
.contact-us .first-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 4rem;
  margin-top: 3rem;
}

.contact-us .second_section {
  display: flex;
  justify-content: space-between;
}

.contact-section .first_section {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.contact-us .second_section .second-1 {
  min-height: 48px;
}

.desktop_heading {
  display: block;
}

.mobile_heading {
  display: none;
}

.contact-us .first-section .column-1 h1 {
  display: inline;
}
.contact-us .first-section .column-1 {
    flex: 1 1;
    align-self: center;
    display: flex
;
    flex-direction: column;
    gap: 2.5rem;
  }

  .contact-us .first-section .column-2 {
   flex: 1;
  }

.contact-us .first-section .column-1 h1,
.contact-us .first-section .column-2 h1 {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 29px;
  line-height: 51px;
  color: #323232;
  /* display: inline; */
}

.contact-us .first-section .column-1 h2 {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 51px;
  color: #323232;
}

.contact-us .first-section .column-1 h2 {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 166.5%;
  color: #4b4b4b;
}

.contact-us .first-section .column-1 p {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 166.5%;
  color: #4b4b4b;
  margin: 0;
}

.contact-us .first-section .column-1 .contact-social {
  display: flex;
  align-items: center;
}

.contact-us .first-section .column-1 .contact-social .social-button {
  width: 40px;
  height: 40px;
  border: 0.5px solid #7fb401;
  border-radius: 100%;
  margin-right: 9px;
  margin-top: 27px;
  justify-content: center;
  align-items: center;
  display: flex;
}


.contact-us .first-section .contact-us-form {
  border: 1px solid #9c9c9c;
  border-radius: 29px;
  padding: 30px;
  margin-top: 20px;
}

.contact-us .column-2 h2 {
  font-family: "Alice";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 166.5%;
  color: #4b4b4b;
  /* margin-top: 50px; */
}

.contact-us .column-2 p {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 166.5%;
  color: #4b4b4b;
}

.contact-us .column-2 .contact-form-inputs label {
  /* margin-top: 25px; */
  font-size: 13px;
  display: block;
}
.placeholderText {
  font-size: 15px;
}
.contact-us .column-2 .contact-form-inputs input,
.contact-us .column-2 .contact-form-inputs select,
.contact-us .column-2 .contact-form-inputs textarea {
  display: block;
  width: 100%;
  height: 50px !important;
}

/* .contact-us .column-2 .contact-form-inputs textarea {
  width: 100%;
  height: 221px;
} */

.contact-us .column-2 .contact-us-form button {
  font-family: "Alice";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 150%;
  letter-spacing: -0.002em;
  margin-top: 34px;
  height: 60px;
  border: none;
  color: #ffff;
  width: 100%;
  background: #7fb401;
  border-radius: 2px;
}

.contact-form-inputs {
  margin-left: 1px;
}

.contact-form-inputs input {
  height: 50px;
}

.contact-us-heading {
  position: relative;
  display: inline;
}

.contact-us-heading .leaf {
    position: absolute;
    left: 165px;
    top: 8px;
}



@media (max-width: 1355px) {
  .contact-us .first-section .column-1 .contact-social .social-button {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .contact-us {
    width: 100%;
  }

  
  .contact-us .first-section .column-1 h2 {
    font-size: 18px;
  }
  .contact-us .first-section .column-1 h1 {
    font-size: 35px;
  }
}
@media(max-width:1400px) and (min-width:576px){
    .contact-us{
        padding: 0px 75px !important;
    }
}

@media (max-width: 768px) {
  .contact-us-heading {
    position: relative;
    display: inline;
  }
  
  .contact-us .first-section {
    /* display: block; */
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
  }
  .contact-us .first-section .column-1 {
    width: 100%;
    gap: 1.5rem;
  }
}

@media (width: 768px) {
  .custom-md-visible {
    display: inline !important;
  }
  .custom-md-hidden {
    display: none !important;
  }
}

@media (max-width: 525px) {
  .desktop_heading {
    display: none;
  }
  .mobile_heading {
    display: block;
  }

  
  .contact-us .first-section .contact-us-form {
    padding: 26px;
  }
  .contact-us .column-2 .contact-us-form button {
    font-size: 19px;
    margin-top: 29px;
    height: 43px;
  }
  .contact-us .column-2 .contact-form-inputs input,
  .contact-us .column-2 .contact-form-inputs select,
  .contact-us .column-2 .contact-form-inputs textarea {
    height: 43px !important;
  }
 
  .contact-us .first-section .column-1 h2 {
    font-size: 19px;
  }
  .contact-us .first-section .column-1 h1,
  .contact-us .first-section .column-2 h1 {
    font-size: 30px;
  }
  .contact-section {
    display: block !important;
  }
  .first_section div,
  .second_section div {
    width: 50%;
  }
}
