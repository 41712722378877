

.mainHeading {
    color: var(--dark);
    font-size: 45px;
    font-family: var(--alice);
    font-style: var(--normal);
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.recomendedProducts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
}

.card {
    box-shadow: 0px 0px 7px 0px;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.image {
    width: 50%;
    margin: auto;
}

.image img {
    width: 100%;
    /* height: 150px; */
}
.details-button{
font-size: 0.5rem !important;
}
.productName {
    color: var(--gray);
    font-size: 0.8rem;
    font-family: var(--alice);    font-style: var(--normal);
    font-weight: 600;
    line-height: normal;
    text-align: center;
    margin: 10px 0 0 0;

}

.productPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.productPrice p {
    margin: 0;
}

.icons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
}

.icon {
    border: 1px solid #9C9C9C;
    color: var(--green);
    font-size: 17px;
    padding: 5px;
    width: 15%;
    height: auto;
}

.handpickedRecommendation {
    font-weight: 400;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
    color: #121212;
  }
  

  .cardActiveButton {
    background-color: #7fb401;
    color: #ffffff;
    border: 1px solid #7fb401;
  }
  
  .cardInactiveButton {
    background-color: transparent;
    color: #7fb401;
    border: 1px solid #7fb401;
  }
  
  .cardActiveButton:hover {
    color: #ffffff;
  }
  
  .cardInactiveButton:hover {
    color: #7fb401;
  }

  /* .exclusiveProductsWrapper{
    padding: 0 0.5rem;
  } */
  
  .exclusiveProductsWrapper .cardActiveButton {
      flex: 1; /* Makes both buttons take equal width */
      border-radius: 3px;
      font-size: 17px;
      font-weight: 400;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      max-height: 35px;
    }
    .exclusiveProductsWrapper .cardInactiveButton {
      flex: 1; /* Makes both buttons take equal width */
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      padding: 1vw 1.5vw;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      max-height: 35px;
    }
  
    
    
    .exclusiveProductCard {
        height: 100%;
      padding: 11px;
      background-color: #f3fbf2;
      border-radius: 10px;
      position: relative;
      border: 1px solid #dbf2d6;
      overflow: hidden; 
      transition: box-shadow 0.3s ease;
      margin-left: 15px;
      width: calc(100% - 30px) !important;
    }
    
    .exclusiveProductCard:hover {
      box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    }
    
    .exclusiveProductCard::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 3px;
      background-color: #7fb401;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: width 0.4s ease, left 0.4s ease;
    }
    
    .exclusiveProductCard:hover::after {
      width: 100%;
      left: 0;
    }
    
    .exclusiveProductImage {
      width: 100%;
      margin-bottom: 10px;
      border-radius: 10px;
    }
    
    .productDetailsMainContent {
      margin: 0px 10px;
    }
    
    .productDetailsContent {
      display: flex;
      justify-content: space-between;
    }
    
      
  .exclusiveProductsWrapper .productName {
    font-weight: 700;
    font-size: 18px !important;
    text-align: left;
    color: #333333;
  }
  
  .productCategory {
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    margin-bottom: 10px;
  }

  .productVariantName{
    margin-top: -5px;
  }

  .starMargin{
    margin-top: 5px;
  }
@media(min-width: 1441px) {
    .details-button{
        font-size: 1rem;
        }
}

@media(max-width: 1025px) {
    .productContainer {
        padding: 30px 50px 0 50px;
    }

    .buttonsContainer{
        height: auto;
    }
    .mainHeading {
        font-size: 37px;
    }

    .image {
        width: 57%;
    }

    .image img {
        height: 77px;
    }


    .icon {
        width: 22%;
    }
}

@media(max-width: 769px) {
    .image img {
        height: 55px;
    }

    .card {
        padding: 5px;
    }


    .recomendedProducts {
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    }
}

@media(max-width: 576px) {

    .handpickedRecommendation{
        font-size: 25px;
        margin-bottom: 15px;

    }
    .productContainer {
        padding: 20px 20px 0 20px;
    }

    .mainHeading {
        font-size: 27px;
    }

    .image {
        width: 65%;
    }

    .image img {
        height: 65px;
    }

    .recomendedProducts {
        grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }

    .card {
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .productPrice,
    .icons {
        margin-top: 9px;
    }
    
}

