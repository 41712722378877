.footerMainContainer {
   
    background-color: #2b481d;
}
.footer {
    padding: 38px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-family: inherit;
    font-style: var(--normal);
}
/* Logo */
.logoContainer {
    width: 20%;

}

.logoContent {
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: white;
    margin: 15px 0;
}

/* Contact */
.contactContainer {
    width: 25%;
}

.MainHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: white;
    margin-bottom: 14px;
}

.locationIcon {
    width: 20px;
    height: 20px;
    color: var(--green);
    margin: 13px;

}

.subHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: white;
    margin-bottom: 3px;
}

.icons {
    color: var(--green);
    font-size: 24px;
    margin: 13px;

}
.prevent-color-change {

    color: white;
  }


.location {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;

}



.address {
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: white;
    margin: 0;

}

.address span {
    color: white;
    font-weight: 700;
}



/* Follow us */
.socialContainer {
    width: 20%;
}

.social {
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
    position: relative;
    gap: 8px;
}

.socialLink {
    width: 34px;
    height: 33px;
    border: 1px solid white;
    border-radius: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

/* Subscribe */
.subscribeContent {
    font-weight: 400;
    font-size: 15px;
    line-height: 156.52%;
    letter-spacing: 0.005em;
    color: white;
}

.search {
    border: 1px solid var(--green);
    border-radius: 19px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: var(--lightGray);
    padding: 10px 20px;
    width: 100%;
}

.search:focus {
    outline: none;
}

.subscribe {
    color: var(--lightGray);
    position: absolute;
    top: 4px;
    right: 11px;
    cursor: pointer;

}

.searchContainer {
    position: relative;
    display: inline;
    line-height: 4;
}

.backgoundImg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.subscribeContainer {
    width: 20%;
}

/* .marginLeft {
    margin-left: 13px;
} */

.message {
    color: var(--green-4);
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;
}

/* Media query */

@media(min-width:630px) {
    .footer {
        padding: 38px 75px !important;
    }
}
@media(max-width: 1441px) {
    .logoContent {
        font-size: 12px;
    }

    .address {
        font-size: 12px;
    }

    .icons {
        font-size: 20px;
    }

    .location {
        width: 100%;
        margin-right: 13px;
    }

    .subscribeContent {
        font-size: 12px;
    }
    .social{
        width: 90%;
    }
}

@media(max-width: 1025px) {

    .socialLink {
        width: 30px;
        height: 30px;
        font-size: 17px;
    }

    .social {
        width: 100%;
    }

    .subHeading {
        font-size: 13px;
    }

    .logo {
        width: 75%;
    }
}

@media(max-width: 769px) {
    .footer {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 15px;
    }

    .logoContainer,
    .contactContainer,
    .socialContainer,
    .subscribeContainer {
        width: 100%;
    }

    .icons {
        margin: 6px 13px 6px 0;
    }

    .logoContent {
        width: 90%;
    }

    .social {
        width: 60%;
    }

    /* .marginLeft {
        margin-left: 32px;
    } */
}

@media(max-width: 541px) {
    .footer {
        padding: 20px 0px;
        grid-template-columns: auto;
    }

    .logoContent {
        margin: 0 !important;
        margin-top: 15px !important;
    }

    .subscribeContainer {
        padding: 11px 0;
    }

    .socialContainer {
        padding-top: 11px;
    }
}

@media(max-width : 375px){

    .social{
        width: 69%;
    }
}