.container {
  background-image: url(/public/assets/images/home/popupRightImage.png);
  background-size: cover;
  background-position: center;
  height: 500px;
  margin: auto;
}

.transation {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
}

.status {
  font-weight: 700;
  font-size: 30px;
  color: #7fb401;
}

.content {
  color: #555;
  font-style: italic;
}

.button {
  margin-top: 25px;
  padding: 10px;
  font-size: 24px;
  background-color: #7fb401;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  font-family: "Times New Roman", Times, serif;
}

.button a {
  text-decoration: none;
  color: #fff;
}
