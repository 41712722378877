.aboutUs .firstSection,
.aboutUs .secondSection {
  display: flex;
  gap: 20px;
}

.aboutUs .secondSection {
  justify-content: space-between;
}

.aboutUs .firstSection .column1 {
  width: 100%;
  /* padding-left: 110px; */
  /* margin-top: 2rem; */
  /* padding-right: 110px; */
}

.aboutUs .firstSection .column1 .aboutSection {
  margin-top: 4vw;
}

.aboutUs .firstSection .column1 .aboutSection p {
  font-family: var(--alice);
  font-style: var(normal);
  font-weight: 400;
  font-size: 13px;
  line-height: 166.5%;
  color: var(--gray);
  margin-bottom: 0;
}

/* .aboutUs .firstSection .column1 .secondDesc {
  width: 65%;
} */

.aboutSection65 {
  width: 65%;
}

.aboutSection100 {
  width: 100%;
}

.aboutUs .firstSection .column2 {
  margin-top: 0%;
  width: 30%;
}

/* .headingDiv{
    display: inline;
} */

.headingDiv h2 {
  position: relative;
  display: inline;
}

.leaf1 {
  position: absolute;
  top: -2px;
  left: 35px;
}

.aboutUpper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 5%;
}

.leaf2 {
  position: absolute;
  top: 6px;
  right: 20px;
}

.leaf3 {
  position: absolute;
  top: 6px;
  right: 20px;
}
.leaf4 {
  position: absolute;
  top: -2px;
  left: 53px;
}

.aboutUs .firstSection .column1 h2 {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 450;
  font-size: 29px;
  line-height: 51px;
  margin-bottom: 13px;
  color: var(--dark);
  position: relative;
}

.aboutUs .secondSection {
  /* margin-top: 70px; */
  /* padding-left: 110px;
  padding-right: 110px; */
  width: 100%;
}

.aboutUs .secondSection .column1,
.aboutUs .secondSection .column2,
.aboutUs .secondSection .column3 {
  /* width: 31%; */
  display: flex;
}

.aboutUs .secondSection h2 {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: var(--dark);
  margin-bottom: 20px;
  /* height: 65px; */
}

.aboutUs .secondSection p {
  /* margin-bottom: -5%; */
  /* min-height: 59px; */
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: var(--gray);
  margin-left: 20px;
  margin-right: 20px;
  /* margin-top: -2%; */
}

.aboutUs .secondSection .aboutCardContainer .cardImage {
  width: 100%;
  height: 285px;
  object-fit: cover;
}

.aboutUs .secondSection button {
  border: none;
  background: var(--green);
  color: var(--white);
  width: 100%;
  height: 50px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  cursor: pointer;
}

.aboutUs .secondSection .aboutCard {
  margin-top: 25px;
  margin-bottom: 25px;
  flex: 1;
}

.aboutUs .secondSection .aboutCardContainer {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 14px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.secondHeading {
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 450;
  font-size: 29px;
  line-height: 51px;
  margin-bottom: 13px;
  color: var(--dark);
  margin-top: 0px;
  /* margin-left: 8%; */
}

.secondHeading h2 {
  position: relative;
  display: inline;
}

.imgSection {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0% 5%; */
}

.imgSection img {
  margin-top: 25%;
  border-radius: 20px;
  width: 90%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  /* .aboutUs .firstSection .column1 {
    padding-left: 55px;
    padding-right: 55px;
  } */

  .aboutUpper {
    flex-direction: column;
  }

  .imgSection {
    width: 100%;
  }

  .imgSection img {
    margin-top: 8%;
  }
  .aboutSection65 {
    width: 100%;
  }

  /* .aboutUs .secondSection { */
    /* margin-top: 80px; */
    /* padding-left: 55px;
    padding-right: 55px; */
  /* } */

  .aboutUs .secondSection p {
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;

  }
  .aboutUs .secondSection h2 {
    margin-bottom: 10px;
  }

  .aboutUs .secondSection .aboutCard {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .aboutUs .secondSection .aboutCardContainer .cardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .secondHeading {
    /* margin-left: 5%; */
    font-size: 36px;
  }
  .leaf4 {
    position: absolute;
    /* left: 254px; */
  }
}

@media (max-width: 768px) {
  .aboutUs .firstSection {
    height: auto !important;
    flex-direction: column;
  }

  .aboutUpper {
    flex-direction: column;
  }

  .imgSection {
    width: 100%;
  }

  .imgSection img {
    margin-top: 8%;
    width: 100%;
  }
  .aboutSection100 {
    width: 100%;
  }

  .aboutUs .firstSection .column1 {
    width: 100%;
    justify-content: center;
    margin-top: 0;
  }

  .aboutUs .firstSection .column2 {
    width: 100%;
    justify-content: center;
  }

  .aboutUs .firstSection .column1 .secondDesc {
    width: 100%;
    margin-top: 40px;
  }

  .aboutUs .firstSection .column1 .aboutSection h2 {
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
  }

  .aboutUs .firstSection .column1 .aboutSection p {
    font-size: 14px;
    text-align: justify;
  }

  /* .aboutUs .secondSection { */
    /* margin-top: 50px; */
  /* } */

  .leaf1 {
    position: absolute;
    top: -3px;
    left: 29px;
  }

  .leaf2 {
    top: -3px;
    right: 7px;
  }
  .leaf1:last-child {
    top: -3px;
    right: 7px;
  }
  .aboutUs .secondSection p {
    /* min-height: 30px; */
    margin-bottom: 2%;
  }
  /* .secondHeading {
    margin-left: 6.5%;
  } */
  .leaf4 {
    position: absolute;
    /* left: 254px; */
  }
}

@media (max-width: 576px) {
  .aboutUs .secondSection {
    display: block;
  }

  .aboutUs .secondSection .column1,
  .aboutUs .secondSection .column2,
  .aboutUs .secondSection .column3 {
    width: 100%;
  }

  .aboutUs .firstSection{
    padding-left: 15px;
    padding-right: 15px;
  }

  /* .aboutUs .secondSection {
    padding-left: 20px;
    padding-right: 20px;
  } */

  .aboutUs .firstSection .column1 .aboutSection p {
    padding-left: 0;
    padding-right: 0;
  }

  .aboutUs .firstSection .column1 {
    padding: 0px;
  }
  .aboutUs .secondSection p {
    line-height: 22px;
    /* min-height: 42px; */
  }
  .secondHeading h2 {
    /* margin-left: 3.8%; */
    font-size: 25px;
  }
  .leaf4 {
    position: absolute;
    left: 35px;
  }
}

@media (max-width: 376px) {
  .leaf4 {
    position: absolute;
    /* left: 155px; */
  }
}

@media (max-width: 321px) {
  .leaf4 {
    position: absolute;
    /* left: 155px; */
  }
}
