.paragraph{
    font-size: 1.1rem;
}
.dealers-infoImg{
    /* margin-top: 0vh !important; */
    margin-left: 15% !important;
}

@media(min-width: 1600px){
    .dealers-infoImg{
        margin-left: 11% !important;
    }
}

@media(min-width: 1150px) and (max-width: 1599px){
    .dealers-infoImg{
        margin-left: 13% !important;
    }
}

.india-map img{
    width: 100%;
}

.india-map{
    width: 100%;
    margin: 30px auto 0;
    border-radius: 21%;
}


 