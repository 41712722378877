.imgTitle{
    font-family: var(--alice);    font-style: var(normal);
    font-weight: 400;
    font-size: 1rem;
    line-height: 166.5%;
    color: var(--gray);
    margin-bottom: 0;
}



@media(max-width:768px){
    .agricultureCard img{
        width: 100%
        }
}