.productDetailContainer {
  padding: 4.5vw 6.5vw;
  display: flex;
  flex-direction: column;
  gap: 3.5vw;
}

.productDetail {
  display: flex;
  gap: 55px;
  align-items: flex-start;
}

.productImages {
  display: flex;
  gap: 25px;
  max-width: 41vw;
  align-items: flex-start; /* Align items at the top */
}
.productMoreImages {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* overflow: scroll; */
  flex: 0 0 auto;
  overflow-y: auto; /* Allow vertical scrolling */
}

.productMoreImages img {
  cursor: pointer;
  /* border-radius: 8px; */
  transition: transform 0.2s ease; 
}

.productMoreImages img:hover {
  transform: scale(1.1); /* Slightly scale image on hover */
}

.frequentlyBoughtContainer {
  width: 100%;
  /* background-color: rgba(0, 4, 255, 0.144); */
  margin-top: 4vw;
}

.frequentlyBoughtCard {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #dbf2d6;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  padding: 7px;
  max-width: 170px;
  min-height: 214px;
  height: 100% !important;
  flex: 1;
}

.frequentlyBoughtCardPriceRatingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.frequentlyBoughtProductName {
  font-weight: bold;
  /* color: #16a34a; */
  margin-bottom: 0px;
  font-size: 18px;
}
.frequentlyBoughtProductsTotalPrice {
  font-weight: bold;
  /* color: #16a34a; */
  margin-bottom: 0px;
  font-size: 24px;
}

.frequentlyBoughtContainerTitle {
  text-align: start;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 1.2vw;
}

.frequentlyBoughtProductButton {
  width: 100%;
}

.frequentlyBoughtContainerTotalSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.frequentlyBoughtContainerTotal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.frequentlyBoughtContainerMainSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4.5vw;
}

.frequentlyBoughtCardPrice {
  display: flex;
  gap: 10px;
  font-size: 18px;
  margin-bottom: -6.5px;
}

.frequentlyBoughtCardPrice strong {
  font-weight: bold;
  font-size: 18px;
}

.frequentlyBoughtCardPrice span {
  font-size: 18px;
}

.frequentlyBoughtCardSection {
  display: flex;
  align-items: center;
}

.frequentlyBoughtCardImage {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #dbf2d6;
  /* position: relative;   */
}

.frequentlyBoughtCardSelection {
  position: absolute;
  top: 0px;
  z-index: 999;
  right: 0;
  margin: 3px;
  transform: scale(1.2);
  cursor: pointer;
}

.frequentlyBoughtCardTotalSectionButton {
  width: 100% !important;
  min-width: 207px;
}

.frequentlyBoughtCardTotalButtonSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}



.plusIcon {
  font-weight: 900;
  margin: 0px 1.3vw;
  color: #666;
}

.moreImage {
  width: 80px;
  min-width: 50px;
  border: 1px solid #7fb401;
  cursor: pointer;
}

.mainImage {
  overflow: hidden;
  border-radius: 0.4rem;
}

.showVideo {
  aspect-ratio: 1 / 1;
  border-radius: 0.4rem;
}

img.hoverZoom {
  transition: all 0.3s ease 0s;
  width: 100%;
  cursor: crosshair;
}

img.hoverZoom:hover {
  transform: scale(3);
  cursor: zoom-in;
}



.detail {
  display: flex;
  flex-direction: column;
  gap: 35px;
  font-family: "Times New Roman";
}

.productName {
  color: #333333;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 5px;
}

.aboutProduct {
  display: flex;
  justify-content: space-between;
}

.varientContainer {
  display: flex
  ;
      flex-direction: column;
      margin-top: 1rem;
}

.productVarient {
  display: flex;
  align-items: end;
  gap: 1rem;
}

.priceMainContainer {
  display: flex;
  flex-direction: column;
}

.priceContainer {
  font-size: 34px !important;
  display: flex;
  gap: 10px;
  font-weight: 700;
  align-items: center;
  margin-left: -7px;
}
.productPrice {
  display: flex;
  color: #000000;
  font-weight: 700;
  align-items: center;
}

.discountPrice {
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 28px;
  color: #dddddd;
  text-decoration: line-through;
}

.productPrice p {
  margin: 0;
}

.description {
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
}

.description p {
  margin-top: 15px;
  font-size: 18px;
}

.stock {
  color: #79b474;
  font-size: 18px;
  font-weight: 400;
}

.counterContainer {
  display: flex
;
    justify-content: space-around;
    align-items: center;
    width: 185px;
    max-width: 42vw;
    height: 45px;
    border-radius: 2px;
    font-size: 18px;
    border: 1px solid #dddddd;
    padding: 0px 15px;
}

.cursorPointer {
  cursor: pointer;
}
.countValue {
  color: #121212;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.cardActiveButton,
.cardInactiveButton {
  border-radius: 3px;
  font-size: 20px;
  font-weight: 400;
  padding: 0px 15px;
  width: 185px;
  max-width: 42vw;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-wrap: nowrap;
}

.cardActiveButton {
  background-color: #7fb401 !important;
  color: #ffffff;
  border: 1px solid #7fb401;
}

.cardInactiveButton {
  background-color: transparent !important;
  color: #7fb401 !important;
  border: 1px solid #7fb401 !important;
}

.cardActiveButton:hover {
  color: #ffffff;
}

.cardInactiveButton:hover {
  color: #7fb401;
}

/* .productDetail button {
  background: var(--green);
  color: var(--white);
  border: none;
  width: 70px;
  height: 45px;
  margin: 0 10px;
} */

.varientCardContainer button {
  background: var(--green);
  color: var(--white);
  border: none;
  width: 70px;
  height: 50px;
  margin: 0 10px;
}

.buyNow {
  width: 138px !important;
  font-size: 20px;
  padding: 7px;
  margin: 0 !important;
}

.productLink {
  width: 80px;
  height: 50px;
  margin: 0 10px;
}

.cart {
  font-size: 31px;
}

.save {
  font-size: 23px;
}

.varientCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.varientCardButton {
  width: 100% !important;
  background-color: white !important;
  border: 1px solid var(--green) !important;
  /* border-radius:5px !important; */
  color: black !important;
  position: relative !important;
}
.varientCardButtonClicked {
  width: 100% !important;
  color: white !important;
  border: 1px solid var(--green) !important;
  /* border-radius:5px !important; */
  background-color: var(--green) !important;
  position: relative !important;
}

.ratingContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  font-size: 18px;
}

.ratingStar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50px;
  height: 22px;
  border-radius: 2px;
  background-color: #7fb401;
  color: #ffffff;
  gap: 3px;
  padding: 5px;
}

.ratingReview {
  color: #999999;
}

.descriptionContent {
  display: flex;
  gap: 10px;
}

.descriptionContent strong {
  font-weight: bold;
  font-size: 18px;
}

.descriptionContent span {
  font-size: 18px;
}

/* media query */

@media (max-width: 1025px) {
  .productDetail {
    flex-direction: column;
  }
  .productImages {
    max-width: 100%;
  }
  

  .description {
    font-size: 20px;
    line-height: 163.023%;
  }

  .buyNow {
    width: 120px !important;
    font-size: 16px;
  }

  /* .productDetail button {
    width: 58px;
    height: 36px;
  } */

  .cart {
    font-size: 28px;
  }

  .save {
    font-size: 21px;
  }

}

@media (max-width: 820px) {

  .detail {
    width: 100%;
  }


  .description {
    font-size: 18px;
  }

  

  .varientContainer {
    flex-direction: row;
    margin: 0px;
  }

  .frequentlyBoughtContainerMainSection {
    flex-direction: column;
    align-items: start;
  }
  .frequentlyBoughtCardTotalButtonSection{
    align-items: center;
  }
  /* .frequentlyBoughtCardSection{

  } */

  .frequentlyBoughtCard{
    min-width: 100px;
    min-height: 128px;
  }

  .frequentlyBoughtContainerTitle{
    font-size: 28px;
    margin-bottom: 10px;
  }

  .frequentlyBoughtContainerTotal {
    flex-direction: row;
    gap: 10px;
  }
  .frequentlyBoughtCardTotalButtonSection{
    flex-direction: row;
    justify-content: space-between;
  }
  .frequentlyBoughtCardTotalSectionButton{
    padding: 10px 15px !important;
    text-wrap: nowrap;
  }
  .frequentlyBoughtContainerTotalSection{
    justify-content: start;
  }
  .frequentlyBoughtContainerTotal{
    width: 100%;
  }
  .frequentlyBoughtCardPriceRatingContainer{
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 576px) {
  .varientCardContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .productDetail , .detail{
    gap: 10px;
  }
  .productImages {
    flex-direction: column-reverse;
  }
  .productMoreImages {
    width: 100%;
    overflow-x: auto;
    flex-direction: row;
    gap: 10px;
  }
  
  .cardActiveButton,
  .cardInactiveButton {
    font-size: 17px;
  }
  .frequentlyBoughtProductName {
    font-size: 16px;
  }
  .aboutProduct {
    flex-direction: column-reverse;
    margin-top: 2%;
    align-items: flex-start;
  }

  /* .productDetail button {
    width: 60px;
    height: 37px;
    margin: 0 3px !important;
  } */

  .description {
    font-size: 16px;
    margin-top: 2%;
  }

  .cart {
    font-size: 22px;
  }

  .save {
    font-size: 17px;
  }


  .frequentlyBoughtCardTotalSectionButton {
    min-width: auto;
  }
}

.bookmark1 {
  background: var(--green) !important;
  color: var(--white) !important;
  border: none !important;
  width: 70px !important;
  height: 45px !important;
  margin: 0 10px !important;
}

.bookmark2 {
  background: var(--white) !important;
  color: var(--green) !important;
  border: 2px solid var(--green) !important;
  border-radius: 5px !important;
  width: 70px !important;
  height: 45px !important;
  margin: 0 10px !important;
}

@media(max-width: 426px) {

  .productName{
    font-size: 25px;
  }
  .productVarient {
    gap: 0.5rem;
  }
  .buttonsContainer {
    gap: 0.5rem;
  }

  .description p{
    font-size: 16px;
  }
  .frequentlyBoughtContainerTitle{
    font-size: 20px;
  }

  .frequentlyBoughtProductsTotalPrice{
    font-size: 20px;
  }
  
}