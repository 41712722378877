.carouselContainer {
    padding: 70px 4rem !important;
    background-color: #edf7e6;
    font-family: "Times New Roman";
    position: relative;
        margin-left: -4rem;
        margin-right: -4rem;
}

.title {
    text-align: center;
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 0px;
}

.subtitle {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 30px;
}

.rating {
  color: #16a34a;
  font-size: 24px;
  font-weight: 600;
}

.reviews {
  font-size: 17px;
}

.carouselWrapper {
  position: relative;
  max-width: 64rem;
  margin: 0 auto;
  overflow: hidden;
  display: flex; /* Use flex for sliding */
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  cursor: pointer;
  z-index: 10; /* Ensure the arrows are above the carousel */
}

.navButton:hover {
  background: #e5e7eb;
}

.navButton:first-of-type {
  left: 10px; /* Place the left arrow outside */
}

.navButton:last-of-type {
  right: 10px; /* Place the right arrow outside */
}

.reviewsContainer {
  display: flex;
  transition: transform 0.5s ease-in-out; /* Smooth transition */
}


.reviewCard {
    background: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 30px 25px;
    width: calc(100% - 30px) !important;
    margin-left: 15px !important;
    min-height: 290px;
    height: 100%;
  }
  

.name {
    color: #333333;
    font-weight: 400;
    font-size: 18px;
}

.ratingContainer {
  display: flex;
  margin-bottom: 0.5rem;
}

.activeStar {
  color: #facc15;
}

.inactiveStar {
  color: #d1d5db;
}

.reviewTitle {
    font-weight: 700;
    color: #7FB401;
    font-size: 22px;
}

.reviewText {
    font-size: 16px;
    color: #555555;
}

.reviewHeader{
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    margin-bottom: 15px;
}

@media(max-width: 766px){
  .carouselContainer{
    margin-left: -1rem;
    margin-right: -1rem;
  }
  }
@media(max-width: 820px){
.carouselContainer{
    padding: 50px 1rem !important;
}
}

@media(max-width: 576px){
    .title{
        font-size: 25px;
    }

    .rating{
        font-size: 18px;
    }

    .reviews{
        font-size: 12px;
    }

    .subtitle{
        align-items: center;
    }
}

@media(max-width: 426px){
    .subtitle{
      margin-bottom: 15px;
    }
}