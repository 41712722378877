.bannerImage {
  position: relative;
  font-style: var(--normal);
  color: var(--white);
  width: 100%;
  height: 100%;
}

.bannerImage .p-carousel-indicators{
  visibility: hidden !important;
}

.custom_carousel_item {
  opacity: 0;
  transition: opacity 0.5s ease; /* Add a transition effect */
}

.custom_carousel_item.active {
  opacity: 1; /* Make the active item fully visible */
}

.bannerImage .p-carousel .p-carousel-indicators {
  display: none !important;
}

.custom_carousel_image {
  width: 100%;
  height: auto;
}

.bannerImage img {
  width: 100%;
  height: 100%;
}
.bannerButton {
  position: absolute;
  bottom: 16%;
  left: 46%;
  background-color: #f9c500;
  font-size: 17px;
  padding: 0.5rem 1.5rem;
  border: none;
  outline: none;
  display: inline-block;
  cursor: pointer;
}

.mainHeading {
  position: absolute;
  bottom: 74px;
  left: 0;
  right: 0;
  font-family: var(--alice);
  font-weight: 400;
  font-size: 45px;
  line-height: 63px;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.35);
  width: 36%;
  margin: auto;
}

.imageContainer {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -83px;
  display: flex;
  justify-content: center;
}

.fertilizer {
  width: 234px;
  height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.boxImg {
  width: 78px;
  height: 78px;
}

.boxHeading {
  font-family: var(--alice);
  font-weight: 600;
  font-size: 15px;
  line-height: 166.5%;
  text-align: center;
  padding: 4px;
  color: var(--white);
  margin-bottom: 0;
}

.boxHeading:hover {
  text-decoration: underline;
}

.fertilizer img {
  width: 100%;
  height: 100%;
}

#box-1 {
  background: var(--mustard);
}

#box-2 {
  background: var(--green-1);
}

#box-3 {
  background: var(--green-2);
}

#box-4 {
  background: var(--green-3);
}

@media (min-width: 1920px) {
  .bannerButton {
    bottom: 20%;
    left: 44%;
  }
}

@media (max-width: 1445px) {
  .bannerButton {
    bottom: 36%;
    left: 44%;
  }
}

@media (max-width: 1280px) {
  .bannerButton {
    bottom: 51%;
    left: 42%;
  }
}

@media (min-width: 1175px) and (max-width: 1285px) {
  .imageContainer {
    position: absolute;
    top:89%;
  }

  .mainHeading {
    position: absolute;
    top: 55%;
  }
}

@media (max-width: 1024px) {
  .mainHeading {
    font-size: 40px;
    width: 43%;
  }
  .bannerButton {
    bottom: 49%;
  }

  .bannerContent {
    position: relative;
    bottom: 55px;
  }

  .fertilizer {
    width: 200px;
    height: 130px;
  }

  .boxImg {
    width: 70px;
    height: 70px;
  }

  .boxHeading {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .mainHeading {
    font-size: 40px;
    width: 60%;
    line-height: 47px;
  }

  .bannerContent {
    position: relative;
    bottom: 65px;
  }
  .bannerButton {
    bottom: 54%;
    left: 37%;
  }

  .fertilizer {
    width: 145px;
    height: 107px;
  }

  .boxImg {
    width: 55px;
    height: 55px;
  }

  .imageContainer {
    bottom: -54px;
  }
}

@media (max-width: 600px) {
  .imageContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    position: static;
    margin-top: 25px;
    grid-gap: 13px;
    /* padding: 0 20px; */
  }
  .bannerButton {
    padding: 0.2rem 1rem;
    font-size: 12px;
    left: 34%;
    bottom: 56%;
  }

  .fertilizer {
    margin: auto;
    width: 100%;
    height: 145px;
  }

  .mainHeading {
    font-size: 25px;
    width: 63%;
    top: -6rem;
    line-height: 35px;
  }

  .boxImg {
    width: 65px;
    height: 65px;
  }

  .boxHeading {
    padding: 2px;
    font-size: 11px;
  }
}

@media (max-width: 428px) {
  .mainHeading {
    top: -8rem;
  }
}

@media (max-width: 376px) {
  .imageContainer {
    grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
    grid-gap: 13px;
  }
  .bannerButton {
    font-size: 10px;
    left: 34%;
    padding: 0.2rem 1rem;
  }
}

@media (max-width: 320px) {
  .fertilizer {
    width: 139px;
    height: 136px;
  }

  .mainHeading {
    font-size: 20px;
  }

  .bannerButton {
    font-size: 10px;
    left: 32%;
  }
}

@media (max-width: 769px) {

}