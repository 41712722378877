@import "./assets/plugins/bootstrap/css/bootstrap.min.css";

@import "./assets/css/main.css";

@import "./assets/css/theme2.css";

@import "./assets/css/custom.css";

@import "./assets/plugins/charts-c3/c3.min.css";

@import "./assets/plugins/summernote/dist/summernote.css";

@import "./assets/plugins/jvectormap/jvectormap-2.0.3.css";

@import "./assets/plugins/bootstrap-multiselect/bootstrap-multiselect.css";

@import "./assets/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";

@import "./assets/plugins/fullcalendar/fullcalendar.min.css";

@import "./assets/plugins/jquery-steps/jquery.steps.css";

@font-face {
  font-family: Amsterdam;
  src: url("./../assets/font/The\ Amsterdam.ttf");
}

@font-face {
  font-family: Amsterdam-italic;
  src: url("./../assets/font/The\ Amsterdam\ Italic.ttf");
}

@font-face {
  font-family: Amsterdam;
  src: url("./../assets/font/The\ Amsterdam.ttf");
}

@font-face {
  font-family: Amsterdam;
  src: url("./../assets/font/The\ Amsterdam.ttf");
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--white);
  overflow-x: hidden !important;
  font-family: var(--alice) !important;
  /* font-size: 16px !important; */
}
/* h1{
  font-size: 29px !important;
  line-height: 1.5 !important;
}
h2{
  font-size: 22px !important;
  line-height: 1.5 !important
}
h2 img{
  top: -7px !important;
} */

/* home-page product carousel */
.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  display: none;
}

.p-carousel-content {
  overflow: unset !important;
}

.p-carousel-container {
  display: flex;
  flex-direction: row;
  /* margin-bottom: -5%; */
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
  position: relative;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #bdbdbd !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef !important;
  width: 1rem !important;
  border-radius: 100% !important;
  height: 1rem !important;
}

/* Product listing varient dropdown */
.productVarient .card {
  width: 185px;
  max-width: 42vw;
}


.productVarient .p-dropdown {
  height: 45px;
  border: 1px solid #DDDDDD;
}
.productVarient .p-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
}

.productVarient .p-dropdown:hover {
  border: 1px solid #dddddd !important;
}

.p-dropdown-item.p-highlight {
  background-color: #7fb401 !important;
  color: #ffffff !important;
}

.productVarient .p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext {
  color: #333333 !important;
  font-size: 18px !important;
  font-weight: 400;
  /* padding:1.5vw !important; */
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 0px 15px;
  color: #333333 !important;
  font-size: 18px !important;
  font-weight: 400;
}

.productVarient .p-icon {
  color: #333333 !important;
}

/* product detail varient */
.productDetailPage .card {
  min-width: max-content !important;
  width: 0px !important;
  margin-bottom: 13px;
}

.productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
.p-inputtext {
  font-size: 23px !important;
}

.productDetailPage .p-dropdown {
  padding: 2px;
  max-height: 45px;
}

/* menus template */
/* .productVarient .p-dropdown .p-dropdown-label.p-placeholder, .p-inputtext {
    color: black !important;
    font-weight: 200;
    font-size: 16px !important;
    padding: 4px !important;
} */
.BannerImage_bannerImage__S0D1R .p-carousel-indicators {
  display: none;
}

.website-template .p-dropdown {
  background-color: transparent;
  height: 35px !important;
  display: flex;
  align-items: center;
  padding-left: 5px;
  border: 1px solid gray;
}

.website-template .p-dropdown .p-dropdown-label {
  color: #000 !important;
  font-size: 14px !important;
}

/* Global Search */
.searchBar .p-inputtext {
  border-radius: 30px !important;
  font-size: 17px !important;
  padding: 10px !important;
  display: flex;
  align-items: center;
  font-family: var(--alice) !important;
}

.BannerImage_bannerImage__S0D1R .p-carousel-indicators {
  display: none;
}

.custom-carousel .p-carousel-item .Products_productContainer__yU9bs {
  margin: auto;
}

@media (max-width: 1025px) {
  .productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
  .p-inputtext {
    font-size: 16px;
  }



  .productDetailPage .p-dropdown .p-dropdown-label.p-placeholder,
  .p-inputtext {
    font-size: 16px !important;
  }
/*
  .p-carousel-container {
    margin-bottom: -2%;
  } */
}

/* @media (max-width: 769px) {
  .p-carousel-container {
    margin-bottom: 1%;
  }
} */

@media (max-width: 426px) {
  .p-carousel .p-carousel-indicators {
    padding: 25px !important;
  }

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 0.8rem !important;
    height: 0.8rem !important;
  }
  /* .p-carousel-container {
    margin-bottom: -25%;
  } */
}

@media (max-width: 376px) {
  .p-carousel .p-carousel-indicators {
    padding: 19px !important;
  }
}

.invalidDataError {
  color: red !important;
}

.spinner_class {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000033;
  z-index: 99999;
  position: fixed;
  width: 100vw;
  overflow: hidden;
  top: 0;
}

.auth_left {
  align-items: center;
  background: #50af31 !important;
  background-image: url("assets/images/bg-pattan.png") !important;
  display: flex;
  height: 100vh;
  padding: 0 20px;
  width: 100% !important;
}

.auth_left .card {
  right: 0 !important;
  width: 500px;
  margin: auto;
}

.auth_left_cart {
  align-items: center;
  background-image: url("assets/images/bg-pattan.png") !important;
  display: flex;
  width: 100% !important;
}

.auth_left_cart .card {
  right: 0 !important;
  width: 500px;
  margin: auto;
}

.cart_card {
  width: 50%;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin-left: auto;
}

@media (max-width: 576px) {
  .cart_card {
    width: 100%;
  }
}

/* @media (max-width: 376px) {
  .p-carousel-container {
    margin-bottom: -10%;
  }
} */

table,
thead,
tbody,
th,
td,
tr {
  text-align: left !important;
  font-size: 15px;
}

/* Global Search bar css */
.search .p-inputtext {
  color: gray !important;
  border-radius: 19px;
}

@media screen and (max-width: 991px) {
  .Logo_container__dlziX.searchBar .p-inputwrapper.search {
    display: none !important;
  }
}

.container {
  max-width: 1200px;
}

#reviewSlider .slick-track{
    display: flex;
}
#reviewSlider .slick-slide{
    float: none;
    height:auto;
}

#reviewSlider .slick-slide > div{
    height: 100%;
}

/* @media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
} */
/*
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
} */

@media all and (min-width: 1400px) {
  .container {
    max-width: 1280px;
  }
}
@media all and (min-width: 1600px) {
  .container {
    max-width: 1480px;
  }
}


.rotating-border--google {
  margin-top: -4px;
  --border-radius: 0.5rem;
  --border-size: 0.20rem;
  --border-bg: conic-gradient( 
    #fff 0.875turn 1.0turn
    #f03139 0.75turn 0.875turn, 
  );
  letter-spacing: 0.1em;
  line-height: 2;
}

.rotating-border {
  --border-radius: 1rem;
  z-index: 9999;
  --border-bg: conic-gradient(#fff,#f03139);
  --padding: 1rem;

  position: relative;
  overflow: hidden;
  padding: calc(var(--padding) + var(--border-size));
  border-radius: var(--border-radius);
  display: inline-block;
  
  &::before {
    content: '';
    display: block;
    background: var(--border-bg);
    width: calc(100% * 1.41421356237);
    padding-bottom: calc(100% * 1.41421356237);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -2;
    animation: spin 3s linear infinite;
  }
  &--reverse::before{
    animation-direction: reverse;
  }
  &::after{
    content: '';
    position: absolute;
    inset: var(--border-size);
    background: white;
    z-index: -1;
    border-radius: calc(var(--border-radius) - var(--border-size));
  }
}
@keyframes spin {
  from {transform: translate(-50%, -50%) rotate(0);}
  to   {transform: translate(-50%, -50%) rotate(360deg);}
}