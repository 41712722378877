.galleryContainer{
    padding: 3rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;

}

.outerContainer{
    /* padding: 50px 50px 0 50px; */
    text-align: center;
}


.mainHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 29px;
    line-height: 51px;
    margin: auto;
    color: var(--dark);
    position: relative;
    display: inline;
   line-height: 60px;
}

.leaf {
    position: absolute;
    top: -2px;
    right: 27px;
}
.leaf2 {
    position: absolute;
    top: 71px;
    right: 45%;
}
.leaf3 {
    position: absolute;
    top: 6px;
    right: 50%;
}



.image {
    height: 250px;
}

.galleryContent {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 21px;
}

.videoContainer {
    position: relative;
    padding: 4rem;
}

.background {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.subHeading {
    font-family: var(--alice);
    font-weight: 00;
    font-size: 29px;
    line-height: 51px;
    margin: auto;
    text-align: center;
    
    margin: 15px auto;
    color: var(--black) !important;
}

@media(max-width: 1400px) and (min-width: 576px) {
    .galleryContainer{
        padding: 3rem 4rem 1rem !important;
    
    }

    .videoContainer{
        margin: 0 -4rem;
    }
}

@media(max-width: 575px){
    .leaf3 {
        top: 0px;
    }
}
@media(max-width:1024px) {
    .leaf {
        top: 2px;
    }


    .mainHeading {
        font-size: 35px;
    }

    .subHeading {
        font-size: 30px !important;
    }

    .image {
        height: 170px;
        border-radius: 15px;
    }

    .image img, video {
        border-radius: 15px;
        height: 100%;
        width: 100%;
    }
    .leaf2{
        position: absolute;
        top: 51px;
        right: 44%;
    }
    
}
@media(max-width:769px) {    
    .leaf2 {
    position: absolute;
    top: 53px;
    right: 42%;
}}
@media(max-width:575px) {
    .leaf {
        top: -6px;
    }

    .mainHeading {
        font-size: 19px;
    }

    .subHeading {
        font-size: 19px !important;
        line-height: 31px;
    }
    .leaf2 {
        position: absolute;
        top: 34px;
        right: 39%;
    }
    .galleryContainer{
        padding-top: 2rem;
        padding-bottom: 0rem;
        gap: 2rem;
    
    }

    .videoContainer{
        padding: 4rem 0rem; 
    }
}