.container {
  /* padding: 40px 110px; */
  margin-top: 50px;
  margin-bottom: 7rem;
  position: relative;
}

.background {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.headingContainer {
  text-align: center;
  margin-top: 2vw;
  margin-bottom: -3vw;
  padding-top: 2vw;
}

.mainHeading {
  position: relative;
  display: inline;
  font-family: var(--alice);
  font-style: var(--normal);
  font-weight: 400;
  font-size: 29px;
  line-height: 51px;
}

.leaf {
  position: absolute;
  top: -4px;
  right: 6px;
}
.btn {
  color: white;
  background: var(--mustard);
  height: 50px;
  width: 200px;
  border: none;
  position: relative;
  top: 90%;
  font-family: "Alice";
  border-radius: 5px;
}

.btn:hover {
  transform: translate(-5px, -5px) scale(1.1);
}

.coreContainer {
  display: flex;
  justify-content: space-between;
  font-style: var(--normal);
  width: 100%;
  position: relative;
  top: 5rem;
}

.coreIdea {
  width: 23%;
  height: 247px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgContainer {
  color: var(--green) !important;
  font-size: 38px;
}

.heading {
  font-family: var(--alice);
  font-weight: 400;
  font-size: 25px;
  line-height: 166.5%;
  margin: 15px auto;
}

.content {
  font-family: var(--alice);
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--gray);
}

@media (max-width: 1024px) {
  /* .container {
    padding: 34px 50px;
  } */

  .btn {
    height: 40px;
    width: 150px;
    font-size: 15px;
  }

  .mainHeading {
    font-size: 38px;
  }

  .heading {
    font-size: 14px;
    margin: 12px auto;
  }

  .coreIdea {
    padding: 14px;
    height: 212px;
    /* width: 50%; */
  }

  .content {
    margin: 0;
    font-size: 12px;
  }
  .background {
    height: 7rem;
  }
  .coreContainer {
    top: 4rem;
  }
  .leaf {
    top: 1px;
    right: 12px;
  }
}

@media (max-width: 800px) {
  .background {
    height: 30% !important;
  }

  .mainHeading {
    font-size: 33px;
  }

  .btn {
    height: 38px;
    width: 140px;
    font-size: 14px;
    position: relative;
    top: 70%;
  }

  /* .container {
    margin-bottom: 25px;
  } */
  .coreContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    position: static;
    /* margin-top: 18%; */
    grid-gap: 13px;
    position: relative;
    top: 2rem;
    /* padding: 0 20px; */
  }

  .coreIdea {
    width: 100%;
    height: auto;
  }

  .leaf {
    top: -1px;
    right: 10px;
  }
}

@media (max-width: 541px) {
  /* .container {
    padding: 20px;
  } */

  .coreContainer {
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    /* margin-top: 59px; */
    grid-gap: 13px;
    /* margin-top: 14%; */
    /* top: -2rem; */
  }

  .mainHeading {
    font-size: 23px;
  }
  .leaf {
    top: -6px;
    right: 2px;
  }
}
