
.testimonialContainer{
  margin-top: 3%;
   font-family: inter;
   padding: 0 10%;
}
.carousel {
  background-color: #2b481d;
  color: var(--white);
  margin: 2% 0;
  box-shadow: 7px 7px 10px rgb(97, 96, 96);
}

.carousel h2 {
  color: var(--mustard);
  font-size: 20px;
}
::before {
  color: green !important;
}
li.slick-active {
  height:0px !important;
}
.kissan {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  margin-bottom: 9%;
}
.testimonialForm {
  margin-top: 5%;
  padding: 1.5vw;
  border: 3px solid var(--light-3);
  /* box-shadow: 0.1px 0.1px 0.1px 0.1px; */
  font-size: 15px !important;
}

.button {
  color: var(--white);
  font-family: var(--alice);
  font-size: 20px;
  font-style: var(--normal);
  line-height: 150%;
  letter-spacing: -0.33px;
  border: none;
  background-color: var(--green);
  padding: 7px 20px;
  border-radius: 5px;
}

.quote1 {
  position: relative;
  right: 14px;
  top: 14px;
}

.quote2 {
  position: relative;
  bottom: 5px;
}
.data input{
  height: 50px !important;
}
select#product_id {
  height: 50px;
}
textarea {
  height: 50px !important;
}
.testimonailLeaf{
  position: relative;
  bottom: 57px;
  left: 80px;
}
@media (max-width: 576px) {
  .testimonialContainer{
    padding: 0 8%;
  }
  .testimonialContainer h1{
    font-size: 30px !important;
  }
  .testimonialForm{
    margin-top: 15%;
  }

  .testimonailLeaf{
    bottom: 52px;
    left: 60px;
  }
}

@media (max-width: 376px) {
  .form-label {
    font-weight: 400;
  }
}

@media (max-width: 321px) {
  h1 {
    font-size: 2rem;
  }

  .data p {
    text-align: unset;
  }
  .kissan {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}
