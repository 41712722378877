.faqWrapper {
  display: flex;
  gap: 60px;
  font-family: "Times New Roman";
}

.faqLeft {
  flex: 1;
  max-width: 50%;
}



.faqRight {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 0.7vw;
}

.faqItem {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  background-color: #fff;
}

.faqItem:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faqQuestion {
  padding: 1vw 1.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  font-weight: 400;
  cursor: pointer;
}

.faqIcon {
  font-size: 1.5rem;
  color: #7fb401;
  transition: transform 0.3s ease;
}

.faqAnswer {
  padding: 15px 20px;
  font-size: 1rem;
  color: #555;
  background-color: #f9f9f9;
}

.faqAnswer p {
  margin: 0;
}

@media (max-width: 820px) {
  .faqWrapper {
    flex-direction: column;
  }
  .faqLeft{
    max-width: 100%;
  }
}



@media(max-width: 426px) {

    .faqQuestion{
        font-size: 15px;
    }
}