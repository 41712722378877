.footerBarMainContainer {
    background:#223D17;
    padding: 1rem 0;
}

.footerBar {
    position: relative;
    display: flex
;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    font-family: var(--alice);
    font-style: var(--normal);
    gap: 1rem;
}

.minfert {
    text-align: center;
    color: var(--white);
    font-weight: 500;
    font-size: 15px;
    line-height: 141.4%;
    margin: 0;
    margin-right: 5px;
    text-wrap: nowrap;
}
.minfert:hover {
    color: var(--white);
}

.menus {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 141.4%;
    width: 25%;
    margin: 0;
}

.navItems{
    color: var(--white);   
}

.dot{
    width: 5px;
    height: 5px;
    background-color: var(--white);
    border-radius: 100%;
}

.policyContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media(min-width:630px) {
    .footerBar {
        padding: 1rem 75px !important;
    }
}
@media(max-width: 1110px){
    .footerBar{
        justify-content: center;
        gap: 0;
    }
}

@media(max-width: 1025px){
    .footerBar{
        padding: 20px 50px; 
    }
    .menus{
        width: 42%;
    }
}

@media(max-width: 769px){
    .menus{
        width: 53%;
    }
}
@media(max-width: 865px){
    .footerBar{
        flex-direction: column;
    } 
}

@media(max-width: 541px){
    .footerBar{
        padding: 20px; 
    } 
    .menus{
        width: 100%;
        padding: 0;
    }
    
}

@media(max-width: 425px){
    .minfert {
        
        font-size: 13px;
        
    }
    .navItems{
        font-size: 13px;  
    }
}